import { CSV_HEADERS_STATICS, CSV_HEADERS_TYPES, DataStorageApi, formatTypes, GetDataBody, Key, tsDataRangesObject, tsOrder } from 'dashboard-services';

import getApiConfig from 'api/ApiConfig';
import FORMULAS from 'resources/constants/Formulas.js';
import { ExcelUtils, Parameters } from 'utils';

import Papa from 'papaparse';

export default(() => {
  const TS_PARAMS_PREFIX = "TS Parameters: "
  const DAILY_DATE_FORMAT = "yyyy-MM-dd"

  const PLAIN_PARSED_TS_PARAMS = ["timeZone", "dateFormat", "range", "lastType", "lastTypeAmount", "order", "formatType", "csvHeaders", "shouldTranspose", "sortKeys", "sortKeysBy", "sortKeysOrder",]
  const SUPPORTED_TS_PARAMS = PLAIN_PARSED_TS_PARAMS.concat(["startDate", "endDate", "keys", "groupName", "columns", "symbols", "metadatas"])

  const csvHeadersOptions = [{
    label: "Symbol",
    value: CSV_HEADERS_STATICS.SYMBOL_VALUES,
    type: CSV_HEADERS_TYPES.DEFAULT
  }, {
    label: "Column",
    value: CSV_HEADERS_STATICS.SYMBOL_COLUMN,
    type: CSV_HEADERS_TYPES.DEFAULT
  }, {
    label: "Group",
    value: CSV_HEADERS_STATICS.GROUP_NAME,
    type: CSV_HEADERS_TYPES.DEFAULT
  }]

  const DEFAULT_CSV_HEADERS = [{
    name: "Description",
    type: CSV_HEADERS_TYPES.METADATA_KEY
  },{
    name: CSV_HEADERS_STATICS.SYMBOL_VALUES,
    type: CSV_HEADERS_TYPES.DEFAULT
  }, {
    name: CSV_HEADERS_STATICS.SYMBOL_COLUMN,
    type: CSV_HEADERS_TYPES.DEFAULT
  }]

  const getBody = ({ keys, timeZone, range, startDate, endDate, lastType, lastTypeAmount = 500, dateFormat = DAILY_DATE_FORMAT, formatType = formatTypes.NCSV, csvHeaders = [], sortKeys = "false", sortKeysBy, sortKeysOrder } = {}) => {
    let builder = new GetDataBody.Builder()
    if(keys?.length === 1 && Object.keys(keys[0]?.symbols || {}).length === 0 && Object.keys(keys[0]?.metadatas || {}).length === 0) {
      builder = builder.withGroupName(keys[0]?.groupName)
    } else {
      keys.forEach(({ columns, symbols, groupName, metadatas }) => {
        const key = new Key.Builder()
          .withColumns(String(columns) === "*" ? undefined : columns.split(","))
          .withSymbols(symbols)
          .withMetadatas(metadatas)
          .withGroupName(groupName)
          .withPattern(true)
          .withPatternExactMatch(false)
          .withSymbolValuesExactMatch(true)
          builder = builder.withKey(key.build().serialize())
      })
    }
    if(range === tsDataRangesObject.between) {
      builder = builder
              .withStartDate(startDate)
              .withEndDate(endDate)
    }
    if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
      builder = builder
              .withLastType(lastType)
              .withLastTypeAmount(lastTypeAmount)
    }
    if(typeof csvHeaders === "object" && csvHeaders?.length > 0) {
      csvHeaders?.forEach(csvHeader => {
        if(csvHeader?.name) {
          builder = builder.withCsvHeader(csvHeader?.name, csvHeader?.type)
        }
      })
    } else {
      DEFAULT_CSV_HEADERS.forEach(defaultCsvHeader => {
        builder = builder.withCsvHeader(defaultCsvHeader?.name, defaultCsvHeader?.type)
      })
    }
    if(!!keys.findIndex && keys.findIndex(k => Object.keys(k.metadatas || {}).length > 0) > -1) {
      builder = builder.withMetadata(true)
    }

    if(sortKeys === "true" && typeof sortKeysBy === "object") {
      builder = builder.withCsvHeaderSort(sortKeysBy?.key, sortKeysBy?.type, Object.values(tsOrder).includes(sortKeysOrder) ? sortKeysOrder : tsOrder.ASC)
    }
    const body = builder
      .withRange(range)
      .withDateFormat(dateFormat)
      .withTimeZone(timeZone)
      .withFormatType(formatType)
      .build()
      .serialize()
    return body
  }

  const getData = async ({ range, order = tsOrder.DESC, keys, sortSymbols, symbolSize,  ...props } = {}) => async dispatch => {
    const body = getBody({ range: range ? String(range).toUpperCase() : tsDataRangesObject.last, keys, ...props })
    const rawResponse = await new DataStorageApi(dispatch(getApiConfig()))
        .getDataStream(body, { size: -1, order, sortSymbols, symbolSize })
        .withHeader('Content-Type', 'application/json')
        .withHeader('Accept', '*/*')
        .withHeader('Expect', 'text/csv')
        .noParsing()
        .build()
        .call()

    const textResponse = await rawResponse.text()
    if(!textResponse) {
      return textResponse;
    }
    const parsedResponse = 
        await new Promise((resolve, reject) =>
          Papa.parse(textResponse, {
            complete: result => {
              const newResult = result.data
              if(newResult[newResult.length - 1]?.length !== newResult[0]?.length) {
                newResult.pop()
              }
              return resolve(newResult)
            },
            error: err => {
              reject(err)
              console.error(String(err))
            }
          })
        )
    return parsedResponse;
  }

  const isTsFormula = formula => formula?.includes(FORMULAS.LOAD_TS) || formula?.includes(FORMULAS.LOAD_TS.replaceAll(".", "_"))

  const extractParamsFromFormula = formula => formula.slice((`=${FORMULAS.LOAD_TS}`).length).replace(/\\"/g, '"').slice(2, -2).replaceAll(", ", ",").split(/","(?!")/).map(s => s.replaceAll("\"\"", "\""))

  const validateTable = async ({ item, cellValue, cell, formula, returnItem = false }) => {
    if((!cellValue || String(cellValue).startsWith(TS_PARAMS_PREFIX)) && isTsFormula(formula)) {
      const parsedFormula = await ExcelUtils.parseFormula({ formula, extractParamsFromFormula })
      const parsedParameters = new Parameters.Builder()
        .withUserParameters(parsedFormula)
        .withGrouping(["groupName", "columns", "symbols", "metadatas"])
        .build();
        // .withGroupingWithIndex(["offsetType", "offsetAmount", "fillType"]) if we need key params
      const toReturn = {
        parsedParameters,
        address: cell.address
      }
      if(returnItem) {
        return toReturn;
      }
      return item.concat(toReturn)
    }
    return item
  }

  const paramsToFormula = params => `=${FORMULAS.LOAD_TS}(${params.getGroupedParams().map(item => Object.entries(item).map(([k, v]) => `"${k}=${v}"`).join(", ")).join(", ")},${Object.entries(params.getParameters()).map(([k, v]) => `"${k}=${v}"`).join(", ")})`

  return {
    getData,
    TS_PARAMS_PREFIX,
    validateTable,
    SUPPORTED_TS_PARAMS,
    PLAIN_PARSED_TS_PARAMS,
    csvHeadersOptions,
    paramsToFormula,
    DEFAULT_CSV_HEADERS,
    isTsFormula,
    DAILY_DATE_FORMAT,
    extractParamsFromFormula
  }
})()