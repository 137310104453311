import { CSV_HEADERS_TYPES, formatTypes, tsOrder } from 'dashboard-services';

import { basicCurvesActions } from 'actions/curves';
import FORMULAS from 'resources/constants/Formulas.js';

import ExcelUtils from './ExcelUtils';
import { Parameters } from './ParameterBuilder';
import TimeSeriesUtils from './TimeSeriesUtils';

export default(() => {
  const FC_PARAMS_PREFIX = "FC Parameters: "

  const PLAIN_PARSED_CURVE_PARAMS = ["timeZone", "dateFormat", "range", "lastType", "lastTypeAmount", "order", "csvHeaders", "symbolSize", "groupName", "column", "root", "product"]
  const SUPPORTED_CURVE_PARAMS = PLAIN_PARSED_CURVE_PARAMS.concat(["startDate", "endDate"])

  const getForwardCurve = async ({ groupName, column, product, root, symbolSize, ...props } = {}) => async dispatch => {
    const params = Object.assign({}, props, {
      sortKeys: "true",
      sortKeysBy: {
        key: basicCurvesActions.SYMBOL,
        type: CSV_HEADERS_TYPES.SYMBOL_KEY
      },
      sortKeysOrder: tsOrder.ASC,
      formatType: formatTypes.CSV,
      symbolSize,
      sortSymbols: basicCurvesActions.SYMBOL
    })
    const keys = [{
      columns: String(column),
      symbols: {
        [basicCurvesActions.SYMBOL]: `${root}_0*`
      },
      metadatas: {
        [basicCurvesActions.PRODUCT]: product,
        [basicCurvesActions.ROOT]: root
      },
      groupName
    }]
    return dispatch(await TimeSeriesUtils.getData({ keys, ...params }))
  }

  const paramsToFormula = params => {
    const { groupName, column, product, root, symbolSize, ...otherParams } = params.getParameters()
    return `=${FORMULAS.LOAD_FC}(${groupName}, ${column}, ${product}, ${root}, ${symbolSize}, ${Object.entries(otherParams).map(([k, v]) => `"${k}=${v}"`).join(", ")})`
  }

  const isCurveFormula = formula => formula?.includes(FORMULAS.LOAD_FC) || formula?.includes(FORMULAS.LOAD_FC.replaceAll(".", "_"))

  const extractParamsFromFormula = formula => formula.slice((`=${FORMULAS.LOAD_FC}`).length).replace(/\\"/g, '"').slice(2, -2).replaceAll(", ", ",").split(/","(?!")/).map(s => s.replaceAll("\"\"", "\""))

  const validateTable = async ({ item, cellValue, cell, formula, returnItem = false }) => {
    if((!cellValue || String(cellValue).startsWith(FC_PARAMS_PREFIX)) && isCurveFormula(formula)) {
      const parsedFormula = await ExcelUtils.parseFormula({ formula, extractParamsFromFormula })
      const parsedParameters = new Parameters.Builder()
        .withUserParameters(parsedFormula)
        .build();
      const toReturn = {
        parsedParameters,
        address: cell.address
      }
      if(returnItem) {
        return toReturn;
      }
      return item.concat(toReturn)
    }
    return item
  }

  return {
    getForwardCurve,
    FC_PARAMS_PREFIX,
    paramsToFormula,
    validateTable,
    PLAIN_PARSED_CURVE_PARAMS,
    SUPPORTED_CURVE_PARAMS
  }
})()